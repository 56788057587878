export const newsList = [
  {
    id: "news01",
    title: "山东黄河流域“清废行动”整治问题403个",
    content: `新华社济南11月28日电(记者袁敏)记者从山东省政府新闻办举行的新闻发布会获悉，山东连续2年组织开展黄河流域“清废行动”，累计排查疑似问题点位3251个，核实确认并整治问题403个。

        山东省生态环境厅副厅长、一级巡视员管言明介绍，“清废行动”是山东省“无废城市”建设工作重点任务，也是贯彻落实黄河流域生态保护和高质量发展重大国家战略的重要体现。行动旨在打击黄河流域固体废物非法转移、倾倒等违法犯罪行为，及时消除环境安全隐患，保障黄河流域生态环境安全。

        管言明说，山东建立遥感疑似问题和信访举报问题“两张清单”。一方面，运用卫星遥感等高新技术，通过遥感影像解译，识别以黄河干流两侧的现状岸线为基准、向陆地各延伸10公里范围内疑似固体废物堆放点位，形成遥感疑似问题清单；另一方面，梳理2019年以来沿黄9市全域范围群众举报和信访件，形成省级信访举报问题清单。

        山东还构建起固体废物非法倾倒、堆存等违法行为及时发现和处置的联合工作机制，实行闭环管理，确保问题整改落实到位。在危险废物领域，2021年以来共查办刑事案件262起，有效防范了危险废物污染事故的发生。

        据悉，山东自2022年起实施《山东省“无废城市”建设工作方案》，积极探索工业、农业、生活等各领域固体废物减量化、资源化、无害化处理处置新方法、新路径。目前全省畜禽粪污染综合利用率稳定在90%以上，社区城市居民小区生活垃圾分类投放设施覆盖率超过95%，实施强制性清洁生产的企业已连续4年超过1000家。`,
    createTime: "2021-12-01",
  },
  {
    id: "news02",
    title: "震惊：近两年山东妹子平均身高已到达19x",
    content: `新华社济南12月1日电(记者韩梅梅)记者从山东省青少年信息统计局获悉，山东连续2年内的青少年身高平均身高已达到183cm，其中值得一提的是东营的女性青少年平均身高竟达到了193cm，达到了历史最高`,
    createTime: "2021-12-02",
  },
  {
    id: "news02",
    title: "山东神秘女子单手举起一头牛",
    content: `新华社济南12月1日电(记者李东)记者从东营市东营区的部分群众口中得知，有人在半晚时分看到一头牛缓缓升起，仔细一看原来下方还有一道人影，天呀竟然单手举起一头牛，不！竟然还只是用了小拇指。据知情人士透漏，看体型此人应该是个女子，身高在158左右，体重大概100斤。可惜的是，如此惊人的场景竟然没被拍下，这可真是人类历史上的一大损失。`,
    createTime: "2021-12-02",
  },
  {
    id: "news03",
    title: "现实版 “一骑当百” ？",
    content: `新华社济南12月1日电(记者张贺伟)记者潜入山东省某个异人组织，经过5年时间的卧薪尝胆，终于接触到了组织内人类战力天花板的存在，据说这位天花板级别的异人拥有顶级异能，能够在对战中一骑当百。曾经以一己之力对抗不完全统计有五百多成员的黑帮组织，最终此黑帮组织直接全灭，而此神秘异人毫发无伤。经过记者多方面的探查，最终终于得知此异人竟然是个女性，个子不高，不到1米6，姓张名字里有一个璇字，平常喜欢隐藏到普通人当中，与普通人无异。如此高手竟甘于平凡，其思想觉悟远非我等能及。`,
    createTime: "2021-12-02",
  },
  {
    id: "news04",
    title: "山东“产业大脑”深挖工业大数据宝藏",
    content: `“叶片厚、耐冲泡、黄绿汤、板栗香”，卓越的自然地理条件赋予日照绿茶独特品质，也滋养着当地近1200家茶企、众多从业者。不过，受小规模分散经营、低价茶冲击等影响，日照绿茶多年来饱受“好茶卖不上好价”的困扰。`,
    createTime: "2021-12-02",
  },
];
